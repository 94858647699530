<template>
  <v-card>
    <v-card-title
      >{{ $t("tag.publishing.title") }}
      <v-spacer />
      <v-btn
        v-if="permitted('Mqtt.Delete')"
        @click="removeConfirm"
        outlined
        text
        >{{ $t("tag.publishing.reset." + tabIdx) }}
      </v-btn>
    </v-card-title>
    <v-tabs v-model="tabIdx" background-color="accent" dark>
      <v-tab> {{ $t("tag.publishing.mqtt") }}</v-tab>
      <v-tab> {{ $t("tag.publishing.sparkplug") }}</v-tab>
      <v-tab v-if="localValue?.deveui">
        {{ $t("tag.publishing.modbus") }}
      </v-tab>
      <v-tab v-if="localValue?.deveui">
        {{ $t("tag.publishing.opc") }}
      </v-tab>

      <v-tab-item :key="0">
        <publishing-mqtt v-model="localValue" />
      </v-tab-item>
      <v-tab-item :key="1">
        <publishing-sparkplug v-model="localValue" />
      </v-tab-item>
      <v-tab-item :key="2" v-if="localValue?.deveui">
        <modbus-publish :deveui="localValue?.deveui" />
      </v-tab-item>
      <v-tab-item :key="3" v-if="localValue?.deveui">
        <opc-publish :deveui="localValue?.deveui" />
      </v-tab-item>
    </v-tabs>

    <v-card-text>
      <delete-dialog
        :dialog="confirmDelete"
        v-on:confirm-delete="removePublishing"
        v-on:cancel-dialog="confirmDelete = false"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import PublishingMqtt from "./PublishingMqtt.vue";
import PublishingSparkplug from "./PublishingSparkplug.vue";
import ModbusPublish from "../modbus/ModbusPublish.vue";
import tagRepository from "../../api/repositories/tagRepository";
import OpcPublish from "@/components/opc/OpcPublish.vue";
import DeleteDialog from "@/components/common/DeleteDialog.vue";

export default {
  name: "PublishingTab",

  components: {
    OpcPublish,
    PublishingMqtt,
    ModbusPublish,
    PublishingSparkplug,
    DeleteDialog,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  model: { prop: "value" },

  data() {
    return { tabIdx: 0, confirmDelete: false };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    async removeConfirm() {
      this.confirmDelete = true;
    },
    async removePublishing() {
      // NOTE: TabIdx is the same as the Enum for publishing type
      await tagRepository.removePublishing(this.localValue.deveui, this.tabIdx);
      this.$emit("refresh");
      this.confirmDelete = false;
    },
  },
};
</script>
