import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { mapGetters, mapState } from "vuex";

export const severities = {
  0: "INFO",
  1: "WARNING",
  2: "ERROR",
};

export const severityColors = {
  0: "green",
  1: "yellow",
  2: "red",
};

export default {
  data() {
    return {
      storedIdentifier: null,
      eventHub: null,
    };
  },

  computed: {
    ...mapState("configuration", { hubApiUrl: "SO_API_BASE_URL" }),
    ...mapGetters("users", { hubToken: "token" }),
  },

  methods: {
    event(e) {
      console.error(
        "Unimplemented event() function: Please create an event method in component.",
        e
      );
    },

    attach(identfier) {
      if (this.eventHub === null) return;
      this.eventHub.invoke("Subscribe", identfier);
      this.eventHub.on("BroadcastEvent", this.event);
      this.storedIdentifier = identfier;
    },

    detach() {
      if (this.eventHub === null) return;

      this.storedIdentifier = null;
      this.eventHub.off("BroadcastEvent");
    },

    async initHub() {
      if (this.hubToken === undefined || this.hubApiUrl === undefined) {
        console.error("Could not find token for user to use for event hub");
        return;
      }

      var url = `${this.hubApiUrl}/hub-events`;

      this.eventHub = new HubConnectionBuilder()
        .withUrl(url, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => this.hubToken,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      await this.eventHub.start();

      this.eventHub.onreconnected(() => {
        if (this.storedIdentifier == null) return;

        this.eventHub.invoke("Subscribe", this.storedIdentifier);
        this.eventHub.on("BroadcastEvent", this.event);
      });
    },
  },

  async beforeDestory() {
    if (!this.eventHub) return;

    this.eventHub.off("BoradcastEvent");
    await this.eventHub.stop();

    this.eventHub = null;
    this.storedIdentifier = null;
  },
};
