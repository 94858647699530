<template>
  <v-container fluid>
    <v-row>
      <opc-node-field
        @save="createPublishingNode"
        :deveui="deveui"
        :monitoring-node="false"
      />
    </v-row>
    <v-row>
      <v-data-table
        class="mt-2"
        style="width: 100%"
        :items="nodes"
        :headers="headers"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="selectRemoveNode(item)">
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-row>

    <delete-dialog
      :dialog="confirmDelete"
      v-on:confirm-delete="removeNode"
      v-on:cancel-dialog="
        () => {
          selectedNode = undefined;
          confirmDelete = false;
        }
      "
    />
  </v-container>
</template>

<script>
import OpcNodeField from "@/components/opc/OpcNodeField.vue";
import opcRepository from "@/api/repositories/opcRepository";
import DeleteDialog from "@/components/common/DeleteDialog.vue";

import { mapActions, mapState } from "vuex";

export default {
  props: {
    deveui: {
      default: undefined,
    },
  },

  components: { OpcNodeField, DeleteDialog },
  data() {
    return {
      confirmDelete: false,
      selectedNode: undefined,
      headers: [
        { text: this.$t("opc.node.fields.serverId"), value: "serverId" },
        { text: this.$t("opc.node.fields.key"), value: "key" },
        { text: this.$t("opc.node.fields.nodeId"), value: "nodeId" },
        { text: "", value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions("opc", ["getNodesByDeveui"]),
    ...mapActions("alert", ["error"]),

    selectRemoveNode(item) {
      if (item?.opcNodeId === undefined) return;
      this.selectedNode = item.opcNodeId;
      this.confirmDelete = true;
    },

    async removeNode() {
      if (this.selectedNode === undefined) {
        console.error("Selected node was undefined");
        return;
      }

      const result = await opcRepository
        .deleteNode(this.selectedNode)
        .then(() => true)
        .catch(() => false);
      if (!result) await this.error(this.$t("opc.deleteErrorAlert"));

      await this.getNodesByDeveui(this.deveui);

      this.selectedNode = undefined;
      this.confirmDelete = false;
    },

    async createPublishingNode(payload) {
      const result = await opcRepository
        .createNode(this.deveui, payload)
        .then(() => "")
        .catch((d) => d.response.data);
      if (result !== "") {
        console.error(result);
        return;
      }

      // Fetch the nodes for this device again to update the dataTable
      await this.getNodesByDeveui(this.deveui);
    },
  },
  computed: {
    ...mapState("opc", ["nodes"]),
  },

  async created() {
    await this.getNodesByDeveui(this.deveui);
  },

  watch: {
    async deveui() {
      await this.getNodesByDeveui(this.deveui);
    },
  },
};
</script>
