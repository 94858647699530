<template>
  <v-container fluid>
    <v-row>
      <modbus-register-field
        @save="create"
        :registers="filteredRegisters"
        :register="selectedRegister"
        :keys="keys"
        write
      />
    </v-row>
    <v-row>
      <v-btn
        class="mt-2 ml-2"
        v-if="registers.length > 0"
        color="primary"
        @click="pollRegisters"
        >{{ $t("modbus.register.poll") }}
      </v-btn>
      <v-data-table
        :items.sync="filteredRegisters"
        :headers="headers"
        class="mt-2"
        style="width: 100%"
        ref="dtable"
      >
        <template v-slot:[`item.register`]="{ item }">
          <v-chip
            >{{
              regCountByType(item.valueType) > 1
                ? item.register +
                  " - " +
                  (parseInt(item.register) +
                    (regCountByType(item.valueType) - 1))
                : item.register
            }}
          </v-chip>
        </template>

        <template v-slot:[`item.valueType`]="{ item }">
          <v-chip
            >{{ $t("modbus.register.valueTypes." + item.valueType) }}
          </v-chip>
        </template>

        <template v-slot:[`item.byteOrder`]="{ item }">
          <v-chip
            >{{ $t("modbus.register.byteOrders." + item.byteOrder) }}
          </v-chip>
        </template>

        <template v-slot:[`item.modbusMasterId`]="{ item }">
          {{ getMasterNameById(item.modbusMasterId) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="remove(item.modbusRegisterId)" color="error" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import modbusMixin from "../../_helpers/modbusMixin";
import modbusRepository from "../../api/repositories/modbusRepository";
import ModbusRegisterField from "./ModbusRegisterField.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  props: {
    deveui: {
      default: undefined,
    },
  },

  components: { ModbusRegisterField },

  mixins: [modbusMixin],

  data() {
    return {
      registers: [],
      selectedRegister: undefined,
      headers: [
        {
          text: this.$t("modbus.register.fields.master"),
          value: "modbusMasterId",
        },
        { text: this.$t("modbus.register.fields.slave"), value: "slave" },
        { text: this.$t("modbus.register.fields.key"), value: "key" },
        { text: this.$t("modbus.register.fields.register"), value: "register" },
        {
          text: this.$t("modbus.register.fields.valueType"),
          value: "valueType",
        },
        {
          text: this.$t("modbus.register.fields.byteOrder"),
          value: "byteOrder",
        },
        {
          text: this.$t("modbus.register.polledValue"),
          value: "polledValue",
        },
        { text: "", value: "actions" },
      ],
    };
  },

  computed: {
    ...mapState("tagData", ["keys"]),
    ...mapGetters("modbus", ["getMasterNameById"]),

    filteredRegisters() {
      return this.registers.filter((d) => d.action == 2);
    },
  },

  methods: {
    ...mapActions("tag", ["getKeysForTag"]),
    ...mapActions("modbus", ["getMasters"]),

    async create(reg) {
      var idx = this.registers.findIndex(
        (d) => d.modbusRegisterId == reg.modbusRegisterId
      );
      if (reg.modbusRegisterId != undefined && idx != -1)
        this.registers.splice(idx, 1);
      else reg.modbusRegisterId = crypto.randomUUID();

      this.registers.push(reg);
      this.selectedRegister = undefined;

      // Ensure that deveui is present on the Register
      reg.nodeId = this.deveui;

      await modbusRepository.createRegister(reg.modbusMasterId, reg); // FIXME Add logging to indicate that something went right or wrong
    },

    async remove(registerId) {
      this.$todo(registerId);
      var idx = this.registers.findIndex(
        (d) => d.modbusRegisterId == registerId
      );
      if (registerId != undefined && idx != -1) this.registers.splice(idx, 1);
      await modbusRepository.removeRegister(registerId); // FIXME Add logging to indicate that something went right or wrong
    },

    async pollRegisters() {
      var result = await modbusRepository
        .poll(this.filteredRegisters)
        .then((d) => d.data)
        .catch(() => []);
      if (result.length == 0) return;

      for (var r of result) {
        var register = this.registers.find(
          (d) => d.modbusRegisterId == r.registerId
        );
        if (register === undefined) continue;

        register.polledValue = r.value;
      }

      var temp = this.registers;
      this.registers = [];
      this.$nextTick(() => {
        this.registers = temp;
      });
    },
  },

  async created() {
    if (this.deveui == undefined) return;

    await this.getMasters();
    this.registers = await modbusRepository.getAllRegisters(this.deveui);
    await this.getKeysForTag({ tagId: this.deveui });
  },

  watch: {
    async deveui(val) {
      if (val == undefined) {
        this.registers = [];
      }

      // Fetch all the registers for the device
      this.registers = await modbusRepository.getAllRegisters(val);
      await this.getKeysForTag({ tagId: val });
    },
  },
};
</script>
