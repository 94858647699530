<template>
  <v-card class="pa-2 elevation-12">
    <v-row v-if="detail != null && rawData.length > 0">
      <!-- Get top 1 -->
      <v-col
        cols="12"
        :sm="data.sm"
        :md="data.md"
        v-for="data in datas"
        :key="data.key"
        class="fadein"
      >
        <div class="value-card">
          <div class="value-card-label" :key="data.key">
            {{ data.label }}
          </div>

          <div class="value-card-value" :key="data.value">
            {{ valFormatted(data.value) }}
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="headers"
          :items="rawData"
          :items-per-page="16"
          :footer-props="{ disableItemsPerPage: true }"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            <p>{{ humanDate(item.createdAt) }}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-else>
      <h3 style="text-align: center; width: 100%">
        No infomation to show here yet.
      </h3>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "TagDetailStats",

  props: {
    deveui: {
      required: true,
    },
  },

  data() {
    return {
      occupiedKeys: [],
      datas: [],
      rawData: [],
      headers: [
        { text: this.$t("tagdata.fields.key"), value: "label" },
        { text: this.$t("tagdata.fields.value"), value: "value" },
        { text: this.$t("tagdata.fields.createdAt"), value: "createdAt" },
      ],
    };
  },

  methods: {
    ...mapActions("tag", ["getDetails"]),

    valFormatted(val) {
      let d = +parseFloat(val).toFixed(3);

      return !d ? val : d;
    },

    constructKeys() {
      if (!this.detail.keys || this.detail.keys.length < 0) return;

      this.occupiedKeys = Object.keys(this.detail.keys)
        .filter(
          (e) =>
            e.toLowerCase() !== "vdd" &&
            e.toLowerCase() !== "battery" &&
            e.toLowerCase() !== "rssi"
        )
        .slice(0, 4);

      if (this.hasVdd) {
        this.occupiedKeys[2] = Object.keys(this.detail.keys).find(
          (e) => e.toLowerCase() == "vdd" || e.toLowerCase() == "battery"
        );
      }
      if (this.hasRssi) {
        this.occupiedKeys[3] = Object.keys(this.detail.keys).find(
          (e) => e.toLowerCase() == "rssi"
        );
      }
    },

    constructData() {
      this.constructKeys();
      let result = [];
      for (let k of this.occupiedKeys) {
        let data = this.rawData.find((e) => e.key == k);
        if (data != null) {
          result.push({ ...data, sm: 6, md: 6 });
        }
      }

      this.datas = result;
    },
  },

  computed: {
    ...mapState("tag", ["detail"]),
    ...mapGetters("event", ["lastEvent"]),

    hasVdd() {
      return (
        Object.keys(this.detail.keys).find(
          (e) => e.toLowerCase() == "vdd" || e.toLowerCase() == "battery"
        ) != null
      );
    },

    hasRssi() {
      return (
        Object.keys(this.detail.keys).find((e) => e.toLowerCase() == "rssi") !=
        null
      );
    },
  },

  async created() {
    await this.getDetails(this.deveui);
    if (!this.detail || !this.detail.rawData) return;

    this.rawData = this.detail.rawData.slice(0);
    this.constructData();
  },

  watch: {
    lastEvent(val) {
      if (val != null && val.tag != null && val.tag.deveui == this.deveui) {
        // Loop through the val.data and add the information
        for (let d of Object.entries(val.data.data)) {
          this.rawData.unshift({
            label: d[0],
            value: d[1],
            createdAt: new Date(),
          });

          if (this.occupiedKeys.includes(d[0])) {
            let index = this.datas.findIndex((e) => e.key == d[0]);
            if (index != null) this.datas[index].value = d[1];
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.value-card {
  width: 100%;
  height: 100%;
  display: inline-flex;
}

.value-card-label {
  overflow: hidden;
  background: #1565c0;
  font-size: 1.2rem;
  font-weight: bolder;
  color: white;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.value-card-value {
  overflow: hidden;
  background: #1565c070;
  font-size: 1rem;
  font-weight: bolder;
  color: white;
  width: 100%;
  height: 100%;
  padding: 1rem;
  animation: flashanimation 1.5s;
}

@keyframes flashanimation {
  0% {
    background: #1565c070;
    color: white;
  }

  50% {
    background: grey;
    color: #94afce70;
  }

  100% {
    background: #1565c070;
    color: white;
  }
}
</style>