<template>
  <div>
    <v-card-text>
      <div>
        <h3 class="text-left title">Sparkplug</h3>
        <sparkplug-form
          v-if="localValue.sparkplugMeta"
          v-model="localValue.sparkplugMeta"
        />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="updateMeta" color="primary">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import SparkplugForm from "../mqtt/SparkplugForm.vue";

export default {
  components: { SparkplugForm },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  model: { prop: "value" },

  data() {
    return {
      sparkplugPublish: false,
      mqttMetaCreate: false,
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("tag", ["updateSparkplugMeta"]),

    async updateMeta() {
      if (this.localValue?.sparkplugMeta !== undefined) {
        // Update sparkplug meta
        await this.updateSparkplugMeta({
          deveui: this.localValue.deveui,
          meta: this.localValue.sparkplugMeta,
        });

        // TODO: Display Errors
        return;
      }
    },
  },
};
</script>