import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";
import store from "../../stores/store";

const resource = "/events";

export default {
  getEventsForDeveui(deveui) {
    if (deveui == undefined) return [];

    let user = store.state?.users?.currentUser;
    if (!user) return [];

    let config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/tag/${deveui}`, config)
      .then((d) => d.data)
      .catch((d) => console.error(d));
  },
};
