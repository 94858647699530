<template>
  <v-container class="event-hub-content-container dark my-1">
    <v-row class="event-hub-header" @click="expanded = !expanded">
      <v-col
        cols="12"
        sm="3"
        md="3"
        class="d-flex justify-center align-center"
        >{{ date }}</v-col
      >
      <v-col
        cols="12"
        sm="6"
        md="6"
        class="event-hub-header-title d-flex justify-start align-center"
        ><span>{{ content }}</span></v-col
      >
      <v-col cols="12" sm="3" md="3" class="d-flex justify-center align-center"
        ><v-chip
          x-small
          :color="severityColor"
          style="text-align: cneter; color: white"
          >{{ severity }}</v-chip
        ></v-col
      >
    </v-row>

    <div class="event-hub-expanded pt-6 pa-2" v-if="expanded">
      <div class="event-hub-content">{{ content }}</div>
      <div
        class="evnet-hub-payload text-left mt-2"
        v-if="payload !== undefined && payload != '' && payload != '{}'"
      >
        <span class="font-weight-bold">Payload:</span>
        <json-display v-model="payload" />
      </div>
    </div>
  </v-container>
</template>

<script>
import { severities, severityColors } from "../../_helpers/eventHubMixin";

import JsonDisplay from "../common/JsonDisplay.vue";
export default {
  name: "EventHubContent",

  components: {
    JsonDisplay,
  },

  props: {
    value: {
      default: undefined,
    },

    showIdentifier: {
      default: false,
    },
  },

  data() {
    return {
      expanded: false,
    };
  },

  computed: {
    payload() {
      if (this.value?.payload === undefined || this.value?.payload === "{}")
        return undefined;

      return this.value.payload;
    },

    parseErrror() {
      if (this.value?.payload === undefined) return false;

      try {
        JSON.parse(this.value.payload);
        return false;
      } catch {
        return true;
      }
    },

    date() {
      if (this.value?.timestamp === undefined) return "N/A";

      return this.humanDate(this.value.timestamp, "MMM d HH:mm:ss");
    },

    content() {
      if (this.value?.message === undefined) return "";

      return (
        (this.showIdentifier ? this.value?.identifier ?? "" : "") +
        this.value.message
      );
    },

    severity() {
      if (this.value?.severity === undefined) return "INFO";

      return severities[this.value.severity];
    },

    severityColor() {
      if (this.value?.severity === undefined) return severityColors[0];

      return severityColors[this.value.severity];
    },
  },
};
</script>

<style lang="scss">
.event-hub-content-container {
  font-size: 0.8rem;

  border-radius: 10px;
  background: rgba(110, 110, 110, 0.048);

  .event-hub-header {
    cursor: pointer;

    .event-hub-header-title {
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .event-hub-expanded {
    .event-hub-content {
      text-align: left;
      width: 100% !important;
    }

    .event-hub-payload {
      text-align: left;
    }
  }
}
</style>